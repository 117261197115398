<template>
  <div class="c-criticalityTab tw-mt-8">
    <h2
      class="tw-mb-8 tw-mr-3 tw-text-xl tw-font-bold tw-text-grey34 dark:tw-text-greyc9"
    >
      {{ $t('views.assets.criticalityForm') }}:
    </h2>
    <div class="tw-grid tw-grid-cols-1 tw-gap-3 xl:tw-grid-cols-2 xl:tw-gap-6">
      <UISelect
        v-model="criticality.sla"
        id="acceptableMaxDowntimeSLA"
        name="acceptableMaxDowntimeSLA"
        :label="$t('views.assets.acceptableMaxDowntimeSLA')"
        input-group-class="tw-mb-3"
        :options="acceptableMaxDowntimeSLAOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.sla']"
        :is-error="
          criticalityErrors['assetCriticalityData.sla'] &&
          criticalityErrors['assetCriticalityData.sla'].length > 0
        "
        @change="updateField('sla', criticality.sla)"
      />
      <UISelect
        v-model="criticality.probability_occurence"
        id="probabilityOfOccurrence"
        name="probabilityOfOccurrence"
        :label="$t('views.assets.probabilityOfOccurrence')"
        input-group-class="tw-mb-3"
        :options="probabilityOfOccurrenceOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.sla']"
        :is-error="
          criticalityErrors['assetCriticalityData.sla'] &&
          criticalityErrors['assetCriticalityData.sla'].length > 0
        "
        @change="
          updateField(
            'probability_occurence',
            criticality.probability_occurence
          )
        "
      />
      <UISelect
        v-model="criticality.mail_users"
        id="numberOfMailUsers"
        name="numberOfMailUsers"
        :label="$t('views.assets.numberOfMailUsers')"
        input-group-class="tw-mb-3"
        :options="numberOfMailUsersOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.mail_users']"
        :is-error="
          criticalityErrors['assetCriticalityData.mail_users'] &&
          criticalityErrors['assetCriticalityData.mail_users'].length > 0
        "
        @change="updateField('mail_users', criticality.mail_users)"
      />
      <UISelect
        v-model="criticality.number_users"
        id="numberOfUsers"
        name="numberOfUsers"
        :label="$t('views.assets.numberOfUsers')"
        input-group-class="tw-mb-3"
        :options="numberOfUsersOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.number_users']"
        :is-error="
          criticalityErrors['assetCriticalityData.number_users'] &&
          criticalityErrors['assetCriticalityData.number_users'].length > 0
        "
        @change="updateField('number_users', criticality.number_users)"
      />
      <UISelect
        v-model="criticality.sys_connected"
        id="numberOfConnectedSystems"
        name="numberOfConnectedSystems"
        :label="$t('views.assets.numberOfConnectedSystems')"
        input-group-class="tw-mb-3"
        :options="numberOfConnectedSystemsOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.sys_connected']"
        :is-error="
          criticalityErrors['assetCriticalityData.sys_connected'] &&
          criticalityErrors['assetCriticalityData.sys_connected'].length > 0
        "
        @change="updateField('sys_connected', criticality.sys_connected)"
      />
      <UISelect
        v-model="criticality.lan_dependency"
        id="dependencyOnWANLAN"
        name="dependencyOnWANLAN"
        :label="$t('views.assets.dependencyOnWANLAN')"
        input-group-class="tw-mb-3"
        :options="dependencyOnWANLANOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.lan_dependency']"
        :is-error="
          criticalityErrors['assetCriticalityData.lan_dependency'] &&
          criticalityErrors['assetCriticalityData.lan_dependency'].length > 0
        "
        @change="updateField('lan_dependency', criticality.lan_dependency)"
      />
      <UISelect
        v-model="criticality.estimated_time"
        id="estimatedRecoveryTime"
        name="estimatedRecoveryTime"
        :label="$t('views.assets.estimatedRecoveryTime')"
        input-group-class="tw-mb-3"
        :options="estimatedRecoveryTimeOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.estimated_time']"
        :is-error="
          criticalityErrors['assetCriticalityData.estimated_time'] &&
          criticalityErrors['assetCriticalityData.estimated_time'].length > 0
        "
        @change="updateField('estimated_time', criticality.estimated_time)"
      />
      <UISelect
        v-model="criticality.financial_losses"
        id="financialLosses"
        name="financialLosses"
        :label="$t('views.assets.financialLosses')"
        input-group-class="tw-mb-3"
        :options="financialLossesOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.financial_losses']"
        :is-error="
          criticalityErrors['assetCriticalityData.financial_losses'] &&
          criticalityErrors['assetCriticalityData.financial_losses'].length > 0
        "
        @change="updateField('financial_losses', criticality.financial_losses)"
      />
      <UISelect
        v-model="criticality.image_losses"
        id="imageLosses"
        name="imageLosses"
        :label="$t('views.assets.imageLosses')"
        input-group-class="tw-mb-3"
        :options="imageLossesOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.image_losses']"
        :is-error="
          criticalityErrors['assetCriticalityData.image_losses'] &&
          criticalityErrors['assetCriticalityData.image_losses'].length > 0
        "
        @change="updateField('image_losses', criticality.image_losses)"
      />
    </div>
    <div
      class="tw-mt-3 tw-grid tw-grid-cols-1 tw-gap-3 xl:tw-mt-6 xl:tw-grid-cols-2 xl:tw-gap-6"
    >
      <UISelect
        v-model="criticality.work_week"
        id="workWeek"
        name="workWeek"
        :label="$t('views.assets.workWeek')"
        input-group-class="tw-mb-3"
        :options="workWeekOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.work_week']"
        :is-error="
          criticalityErrors['assetCriticalityData.work_week'] &&
          criticalityErrors['assetCriticalityData.work_week'].length > 0
        "
        @change="updateField('work_week', criticality.work_week)"
      />
      <UISelect
        v-model="criticality.day_off"
        id="freeDay"
        name="freeDay"
        :label="$t('views.assets.freeDay')"
        input-group-class="tw-mb-3"
        :options="freeDayOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.day_off']"
        :is-error="
          criticalityErrors['assetCriticalityData.day_off'] &&
          criticalityErrors['assetCriticalityData.day_off'].length > 0
        "
        @change="updateField('day_off', criticality.day_off)"
      />
      <UISelect
        v-model="criticality.day_required_8h"
        id="dayServiceRequiredFor8h"
        name="dayServiceRequiredFor8h"
        :label="$t('views.assets.dayServiceRequiredFor8h')"
        input-group-class="tw-mb-3"
        :options="dayServiceRequiredFor8hOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.day_required_8h']"
        :is-error="
          criticalityErrors['assetCriticalityData.day_required_8h'] &&
          criticalityErrors['assetCriticalityData.day_required_8h'].length > 0
        "
        @change="updateField('day_required_8h', criticality.day_required_8h)"
      />
      <UISelect
        v-model="criticality.night_required_24h"
        id="nightServiceRequiredFor24h"
        name="nightServiceRequiredFor24h"
        :label="$t('views.assets.nightServiceRequiredFor24h')"
        input-group-class="tw-mb-3"
        :options="nightServiceRequiredFor24hOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="criticalityErrors['assetCriticalityData.night_required_24h']"
        :is-error="
          criticalityErrors['assetCriticalityData.night_required_24h'] &&
          criticalityErrors['assetCriticalityData.night_required_24h'].length >
            0
        "
        @change="
          updateField('night_required_24h', criticality.night_required_24h)
        "
      />
    </div>
    <div class="tw-mt-6 tw-flex tw-justify-between">
      <UIButton
        type="secondary"
        class="tw-max-w-[90px]"
        :label="$t('button.comeback')"
        @click="$emit('setActive', tabs[0])"
      />
      <UIButton
        type="primary"
        :label="$t('button.saveCriticality')"
        v-if="asset?.status_id === 2"
        @click="saveAsset()"
      >
        <template #left>
          <font-awesome-icon
            icon="icon fa-solid fa-check"
            :class="{ icon: true }"
            class="mr-2 text-xs"
          />
        </template>
      </UIButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import UISelect from '@/components/UI/UISelect.vue'
import type { SelectOption } from '@/types/CommonTypes'
import UIButton from '@/components/UI/UIButton.vue'
import type { ITab } from '@/types/TabsTypes'
import type { Nullable } from '@/types/Nullable'
import type {
  IAsset,
  IAssetCriticality,
  IAssetCriticalityForm,
} from '@/types/AssetsTypes'
import { assetsService, errorsService } from '@/main'
import assetsRepository from '@/repositories/AssetsRepository'
import { errorsEnum, type IError, type IErrorElement } from '@/types/ErrorTypes'

interface ICriticalityForm {
  acceptableMaxDowntimeSLA: string
  probabilityOfOccurrence: string
  numberOfMailUsers: string
  numberOfUsers: string
  numberOfConnectedSystems: string
  dependencyOnWANLAN: string
  estimatedRecoveryTime: string
  financialLosses: string
  imageLosses: string
  workWeek: string
  freeDay: string
  dayServiceRequiredFor8h: string
  nightServiceRequiredFor24h: string
}

@Component({
  components: { UIButton, UISelect },
})
export default class CriticalityTab extends Vue {
  @Prop()
  public tabs!: ITab[]

  public criticality: IAssetCriticalityForm = {
    sla: null,
    probability_occurence: null,
    mail_users: null,
    number_users: null,
    sys_connected: null,
    lan_dependency: null,
    estimated_time: null,
    financial_losses: null,
    image_losses: null,
    work_week: null,
    day_off: null,
    day_required_8h: null,
    night_required_24h: null,
  }

  public form: ICriticalityForm = {
    acceptableMaxDowntimeSLA: '',
    probabilityOfOccurrence: '',
    numberOfMailUsers: '',
    numberOfUsers: '',
    numberOfConnectedSystems: '',
    dependencyOnWANLAN: '',
    estimatedRecoveryTime: '',
    financialLosses: '',
    imageLosses: '',
    workWeek: '',
    freeDay: '',
    dayServiceRequiredFor8h: '',
    nightServiceRequiredFor24h: '',
  }

  public get acceptableMaxDowntimeSLAOptions(): SelectOption[] {
    return [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
    ]
  }

  public get probabilityOfOccurrenceOptions(): SelectOption[] {
    return [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
    ]
  }

  public get numberOfMailUsersOptions(): SelectOption[] {
    return [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
    ]
  }

  public get numberOfUsersOptions(): SelectOption[] {
    return [
      { value: 0, label: 'brak' },
      { value: 1, label: 'są' },
    ]
  }

  public get numberOfConnectedSystemsOptions(): SelectOption[] {
    return [
      { value: 0, label: '1' },
      { value: 1, label: 'do 5' },
      { value: 2, label: 'do 10' },
      { value: 3, label: 'powyżej 10' },
    ]
  }

  public get dependencyOnWANLANOptions(): SelectOption[] {
    return [
      { value: 0, label: 'wewnętrznie' },
      { value: 1, label: 'LAN' },
      { value: 2, label: 'WAN' },
      { value: 3, label: 'LAN/WAN' },
    ]
  }

  public get estimatedRecoveryTimeOptions(): SelectOption[] {
    return [
      { value: 0, label: '0' },
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
    ]
  }

  public get financialLossesOptions(): SelectOption[] {
    return [
      { value: 0, label: 'brak' },
      { value: 1, label: '1000' },
      { value: 2, label: '100000' },
      { value: 3, label: 'Powyżej 5000000' },
    ]
  }

  public get imageLossesOptions(): SelectOption[] {
    return [
      { value: 0, label: 'brak' },
      { value: 1, label: 'wewnątrz' },
      { value: 2, label: 'lokalnie' },
      { value: 3, label: 'Polska' },
      { value: 4, label: 'świat' },
    ]
  }

  public get workWeekOptions(): SelectOption[] {
    return [
      { value: 0, label: 'brak' },
      { value: 1, label: 'pn wt śr cz pt' },
    ]
  }

  public get freeDayOptions(): SelectOption[] {
    return [
      { value: 0, label: 'brak' },
      { value: 1, label: 'so nd' },
    ]
  }

  public get dayServiceRequiredFor8hOptions(): SelectOption[] {
    return [
      { value: 0, label: 'brak' },
      { value: 1, label: '8-16' },
    ]
  }

  public get nightServiceRequiredFor24hOptions(): SelectOption[] {
    return [
      { value: 0, label: 'brak' },
      { value: 1, label: '16-8' },
    ]
  }

  public get asset(): Nullable<IAsset> {
    return assetsService.asset
  }

  public get criticalityStore(): Nullable<IAssetCriticality> {
    return assetsService.criticality
  }

  public setCriticalityForm(): void {
    if (this.criticalityStore) {
      this.criticality = {
        sla: this.criticalityStore.sla,
        probability_occurence: this.criticalityStore.probability_occurence,
        mail_users: this.criticalityStore.mail_users,
        number_users: this.criticalityStore.number_users,
        sys_connected: this.criticalityStore.sys_connected,
        lan_dependency: this.criticalityStore.lan_dependency,
        estimated_time: this.criticalityStore.estimated_time,
        financial_losses: this.criticalityStore.financial_losses,
        image_losses: this.criticalityStore.image_losses,
        work_week: this.criticalityStore.work_week,
        day_off: this.criticalityStore.day_off,
        day_required_8h: this.criticalityStore.day_required_8h,
        night_required_24h: this.criticalityStore.night_required_24h,
      }
    }
  }

  // eslint-disable-next-line
  public async updateField(field: string, value: any): Promise<void> {
    if (this.asset && this.asset.id) {
      const id = this.asset.id
      let data: IAssetCriticalityForm = {}
      // @ts-ignore
      data[field] = value
      errorsService.clearScopeErrors(errorsEnum.SaveAssetCriticality)

      await assetsService
        .updateCriticalityField(this.asset.id, data)
        .then(async () => {
          await assetsService.loadCriticality(id).then(async () => {
            this.setCriticalityForm()
          })
        })
    }
  }

  public get isDisabledForm(): boolean {
    return (
      !this.asset ||
      !this.asset.id ||
      this.asset.status_id !== 2 ||
      this.isLoading
    )
  }

  public get isLoading(): boolean {
    return assetsService.isLoading
  }

  public async mounted(): Promise<void> {
    errorsService.clearScopeErrors(errorsEnum.SaveAssetCriticality)
    this.setCriticalityForm()
  }

  public get errors(): IError {
    return errorsService.getScopeErrors(errorsEnum.SaveAssetCriticality)
  }

  public get criticalityErrors(): IErrorElement[] {
    const errors = Object.entries(this.errors)
    const transformErrors: IErrorElement[] = []
    errors.forEach(([key, value]) => {
      if (key.search('assetCriticalityData.') > -1) {
        // eslint-disable-next-line
        transformErrors[key] = value[0]
      }
    })
    return transformErrors
  }

  public async saveAsset(): Promise<void> {
    if (!this.asset || !this.asset.id) return
    await assetsRepository
      .saveCriticality(this.asset!.id, this.criticality)
      .then(async () => {
        if (!this.criticalityErrors.length) {
          if (this.asset && this.asset.id) {
            await assetsService.loadAsset(this.asset!.id)
          }
        }
      })
  }
}
</script>
