export default {
  parameters: {
    parameters: 'Parametry',
    modalEdit: {
      editParameter: 'Edytuj parametr',
      notificationSuccess: 'Parameter zostal  zaktualizowany pomyślnie',
      form: {
        name: 'Nazwa',
        weight: 'Waga',
        selectType: 'Wybierz typ',
        value: 'Wartość',
        types: {
          days: 'Dni',
          minutes: 'Minuty',
          txt: 'Txt',
        },
      },
      buttons: {
        save: 'Zapisz',
        cancel: 'Anuluj',
      },
    },
  },
}
