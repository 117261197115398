<template>
  <div class="c-residualRisksTab tw-mt-8">
    <UITable
      :headers="tableHeaders"
      :sortFill="sortFill"
      :sortDirection="sortDirection"
      @sort="setSortFill"
      :numberOfAllResults="risks.length"
      class="tw-my-4"
      :with-pagination="withPagination"
      :with-number-of-results="withNumberOfResults"
    >
      <tr
        v-for="residualRisk in risks"
        :key="`residualRisksRow_${residualRisk.id}`"
        class="tw-align-top tw-leading-6"
        @click="routeToRisk(residualRisk.id)"
      >
        <td class="tw-p-2.5 tw-leading-6">{{ residualRisk.id }}</td>
        <td class="tw-p-2.5 tw-leading-6">---</td>
        <td class="tw-p-2.5 tw-leading-6">{{ residualRisk.created_at }}</td>
        <td class="tw-p-2.5 tw-leading-6">
          {{ residualRisk.risk_additional_data?.rodo_risk_level ?? '' }}
        </td>
        <td class="tw-p-2.5 tw-leading-6">
          {{
            residualRisk.risk_additional_data?.identification_individual_risk ??
            ''
          }}
        </td>
        <td class="tw-p-2.5 tw-leading-6">
          {{ residualRisk.risk_additional_data?.potential_global_loss ?? '' }}
        </td>
        <td class="tw-p-2.5 tw-leading-6">
          <UIStatus :status="getStatusName(residualRisk.status_id)" />
        </td>
      </tr>
      <template #topRightSide>
        <div
          class="tw-relative tw-ml-auto tw-flex tw-flex-wrap-reverse tw-items-center"
        >
          <UIInput
            v-model="searchingForm.search"
            id="search"
            name="search"
            ui-class="tw-flex tw-relative !tw-mt-0"
            input-group-class="with-icon !tw-w-[230px] !tw-mb-5 !tw-mt-0 tw-mr-2"
          >
            <font-awesome-icon
              icon="icon fa-solid fa-magnifying-glass"
              class="tw-absolute !tw-top-[14px] tw-right-5"
            />
          </UIInput>
          <UIButton
            type="primary"
            :label="$t('button.addPartialRisk')"
            size="medium"
            button-class="!tw-mb-5"
            v-if="showAddNewButton && assetId"
            @click="addNewRisk()"
          >
            <template #left>
              <font-awesome-icon
                icon="icon fa-solid fa-plus"
                :class="{ icon: true }"
                class="tw-mr-2 tw-text-xs"
              />
            </template>
          </UIButton>
        </div>
      </template>
    </UITable>
    <UIButton
      type="primary"
      class="!tw-hidden"
      :label="$t('button.generateRecord')"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import UIInput from '@/components/UI/UIInput.vue'
import UIButton from '@/components/UI/UIButton.vue'
import UITable from '@/components/UI/UITable.vue'
import { ITableHeader } from '@/types/TableTypes'
import UIStatus from '@/components/UI/UIStatus.vue'
import { assetsService, userService } from '@/main'
import { IAssetRisk } from '@/types/AssetsTypes'
import assetsRepository from '@/repositories/AssetsRepository'
import type { Nullable } from '@/types/Nullable'
import type { IUser } from '@/types/UserTypes'

interface ISearchingForm {
  search: string
}

@Component({
  components: { UIStatus, UITable, UIButton, UIInput },
})
export default class ResidualRisksTab extends Vue {
  @Prop({ required: false, default: true })
  public showAddNewButton!: boolean

  @Prop({ required: false, default: null })
  public assetId!: Nullable<number>

  public sortFill: string = 'id'
  public sortDirection: string = 'asc'
  public withPagination: boolean = false
  public withNumberOfResults: boolean = false
  public searchingForm: ISearchingForm = {
    search: '',
  }

  public get risks(): IAssetRisk[] {
    return assetsService.risks
  }

  public getStatusName(statusId: number): string {
    return this.$t('views.tickets.statuses.' + statusId).toString()
  }

  public setSortFill(fill: string): void {
    if (this.sortFill === fill) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      return
    }
    this.sortFill = fill
  }

  public routeToRisk(id: number): void {
    this.$router.push(`/risks/${id}`)
  }

  public get user(): Nullable<IUser> {
    return userService.getUser
  }

  public async addNewRisk(): Promise<void> {
    if (!this.assetId || !this.user) return
    await assetsRepository
      .getNewAssetRisks(this.assetId, Number(this.user.id))
      .then((riskId) => {
        if (riskId) this.$router.push(`/risks/${riskId}`)
      })
  }

  public get tableHeaders(): ITableHeader[] {
    return [
      {
        label: '#',
        fill: 'id',
        sort: true,
      },
      {
        label: this.$t('views.assets.createdBy'),
        fill: 'created_by',
        sort: true,
      },
      {
        label: this.$t('views.assets.createdAt'),
        fill: 'created_at',
        sort: true,
      },
      {
        label: this.$t('views.assets.rodoRiskLevel'),
        fill: 'rodo_risk_level',
        sort: true,
      },
      {
        label: this.$t('views.assets.potentialUnitRisk'),
        fill: 'potential_unit_risk',
        sort: true,
      },
      {
        label: this.$t('views.assets.riskEstimation'),
        fill: 'risk_estimation',
        sort: true,
      },
      {
        label: this.$t('views.assets.status'),
        fill: 'status',
        sort: true,
      },
    ]
  }
}
</script>
