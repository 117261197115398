export default {
  common: {
    email: 'Email',
    password: 'Password',
    repeatPassword: 'Repeat password',
    dontRememberPassword: 'Don’t you remember your password?',
    resetPassword: 'Reset password',
    login: 'Login',
    confirm2FA: 'Confirm with application code',
    loginWithGoogle: 'Login with Google account',
    dontHaveAccount: 'Don’t you have an account yet?',
    doYouHaveAccount: 'Do you already have an account?',
    createAccount: 'Create an account',
    or: 'Or',
    firstName: 'FirstName',
    lastName: 'LastName',
    name: 'Name',
    yes: 'Yes',
    no: 'No',
    value: 'Value',
    phone: 'Phone',
    headerTime: 'Your session time will expire in',
    agree: 'I agree to',
    termsOfUse: 'terms of service',
    enable2fa: 'Enable two-step verification (TOTP Authenticator)',
    passwordRequiredRules: 'Required',
    passwordRules: {
      minLength: 'Minimum 8 characters',
      specialChar: 'Contains at least 1 special character',
      number: 'Contains at least 1 number',
      upperCase: 'Contains at least 1 upper case letter',
      lowerCase: 'Contains min 1 lower case letter',
      baseInfo: 'Base password security policy',
    },
    dashboard: {
      menu: {
        risks: 'Risk management',
        events: 'Event and incident management',
        contextCompany: 'Context company',
        contextRole: 'Context role',
      },
    },
    sessionTimeout: {
      title: 'Your session is about to expire',
      message: 'Your session is about to expire and you will be logged out',
      stillTimeLeftSession:
        'There is still time left until the end of the session',
      ExtendSession: 'Extend your session to continue working.',
      buttons: {
        logout: 'Logout',
        extendSession: 'Extend Session',
      },
    },
    modals: {
      accept: 'Confirm',
      acceptToProceed: 'Accept all consents to proceed',
    },
    identifier: 'Identifier',
    status: 'Status',
    createdAt: 'Creation date',
    endDate: 'Closing date',
    position: 'position',
    show: 'Show',
    positionFrom: 'Positions from',
    total: 'total',
    to: 'to',
    of: 'of',
    type: 'Type',
    other: 'Other',
    all: 'All',
    none: 'None',
    role: 'Role',
    active: 'Active',
    actions: 'Actions',
    description: 'Description',
    notFound: 'Page not found',
    redirect: 'Redirecting...',
    logout: 'You have been logged out',
    logoutDescription: 'You will be redirected to the login page',
    search: 'Search',
    invalidCaptcha: 'Nieprawidłowa captcha',
  },
}
