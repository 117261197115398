<template>
  <div
    class="c-uiModal modal fade"
    :class="{ show: showModal }"
    tabindex="-1"
    aria-modal="true"
    role="dialog"
    v-show="showModal"
  >
    <div
      :class="{
        overlay: true,
        'overlay--heavy': heavyOverlay,
      }"
      @click="close()"
    ></div>
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div v-bind="$attrs" class="modal-content">
        <div class="modal-header border-0">
          <slot name="header" />
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
  inheritAttrs: false,
})
export default class UIModal extends Vue {
  @Prop()
  public showModal!: boolean

  @Prop()
  public heavyOverlay!: boolean

  public close(): void {
    this.$emit('close')
  }
}
</script>

<style lang="scss" scoped>
.c-uiModal {
  display: none;

  &.show {
    display: block !important;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000017;
  &--heavy {
    background-color: var(--ui-bg-color);
  }
}
</style>
