import { Store, Pinia } from 'pinia-class-component'
import type {
  IError,
  IErrorsState,
  IScopeError,
  IScopeErrorsPayload,
} from '@/types/ErrorTypes'
import { toast } from 'vue3-toastify'
import { i18n } from '@/main'
/* import notificationService from '~/store/services/common/NotificationService' */

@Store
export default class ErrorsService extends Pinia {
  public stateErrors: IErrorsState = {
    scopesErrors: {},
    scopeFlowErrors: {},
  }

  public setScopesErrors(payload: IScopeError): void {
    this.stateErrors.scopesErrors[payload.scope] = {}
    this.stateErrors.scopesErrors[payload.scope] = payload.errors
  }

  public setScopesInitErrors(scope: string): void {
    this.stateErrors.scopesErrors[scope] = {}
  }

  public getScopeErrors(scope: string): IError {
    if (!this.stateErrors.scopesErrors[scope]) {
      this.stateErrors.scopesErrors[scope] = {}
    }
    return this.stateErrors.scopesErrors[scope]
  }

  public setScopeFlowErrors(payload: IScopeError): void {
    this.stateErrors.scopeFlowErrors[payload.scope] = {}
    this.stateErrors.scopeFlowErrors[payload.scope] = payload.errors
  }

  public setScopesFlowInitErrors(scope: string): void {
    if (!this.stateErrors.scopeFlowErrors[scope]) {
      this.stateErrors.scopeFlowErrors[scope] = {}
    }
    this.stateErrors.scopeFlowErrors[scope] = {}
  }

  public getScopeFlowErrors(scope: string): IError {
    if (!this.stateErrors.scopeFlowErrors[scope]) {
      this.stateErrors.scopeFlowErrors[scope] = {}
    }

    return this.stateErrors.scopeFlowErrors[scope]
  }

  public initScopesErrors(scopes: string[]): void {
    scopes.forEach((scope: string) => {
      this.setScopesInitErrors(scope)
      this.setScopesFlowInitErrors(scope)
    })
  }

  public setErrors400(payload: IScopeErrorsPayload): void {
    const message = i18n.global.t('notifications.errors.400')

    this.setScopesErrors({
      scope: payload.scope,
      errors: payload.response.data as IError[],
    })

    if (payload.ignoreToast) return
    toast(message, {
      type: 'error',
      position: 'top-right',
    })
  }

  public setErrors401(payload: IScopeErrorsPayload): void {
    if (payload.response.data.errors) {
      this.setScopesErrors({
        scope: payload.scope,
        errors: payload.response.data.errors as IError[],
      })
    }

    if (payload.ignoreToast) return
    const message = i18n.global.t('notifications.errors.401')
    toast(message, {
      type: 'error',
      position: 'top-right',
    })

    this.setScopesErrors({
      scope: payload.scope,
      errors: payload.response.data as IError,
    })
  }

  public setErrors403(payload: IScopeErrorsPayload): void {
    if (payload.response.data.errors) {
      this.setScopesErrors({
        scope: payload.scope,
        errors: payload.response.data as IError,
      })

      /* notificationService.setNotification({ */
      /*   status: 403, */
      /*   text: payload.response.data.errors[0].code, */
      /* }) */
      /* return */
    }

    const message = i18n.global.t('notifications.errors.403')

    if (payload.ignoreToast) return
    toast(message, {
      type: 'error',
      position: 'top-right',
    })
  }

  public setErrors404(payload: IScopeErrorsPayload): void {
    const message = i18n.global.t('notifications.errors.404')

    if (payload.ignoreToast) return
    toast(message, {
      type: 'error',
      position: 'top-right',
    })
  }

  public setErrors413(payload: IScopeErrorsPayload): void {
    const message = i18n.global.t('notifications.errors.413')

    if (payload.ignoreToast) return
    toast(message, {
      type: 'error',
      position: 'top-right',
    })
  }

  public setErrors500(payload: IScopeErrorsPayload): void {
    const message = i18n.global.t('notifications.errors.500')

    if (payload.ignoreToast) return
    toast(message, {
      type: 'error',
      position: 'top-right',
    })
  }

  public setErrorsNotFound(payload: IScopeErrorsPayload): void {
    const message = i18n.global.t('notifications.errors.default')

    if (payload.ignoreToast) return
    toast(message, {})
  }

  public setCustomErrorsMessage(payload: IScopeErrorsPayload): void {
    if (payload.ignoreToast) return

    if (!payload.message) return

    toast(payload.message, {
      type: 'error',
      position: 'top-right',
    })
  }

  public clearScopeErrors(scope: string): void {
    this.setScopesErrors({ scope, errors: {} })
    this.setScopeFlowErrors({ scope, errors: {} })
  }

  public setCustomErrorMessageResponse(payload: IScopeErrorsPayload): void {
    if (payload.response.status) {
      const { status } = payload.response
      if (status === payload.statusCode && payload.message) {
        this.setCustomErrorsMessage(payload)
      } else {
        this.setScopeErrorsFromResponse(payload)
      }
    } else {
      this.setErrorsNotFound()
    }
  }

  public setScopeErrorsFromResponse(payload: IScopeErrorsPayload): void {
    this.setScopesErrors({ scope: payload.scope, errors: {} })
    this.setScopeFlowErrors({ scope: payload.scope, errors: {} })

    if (payload.response.status) {
      const { status } = payload.response
      switch (status) {
        case 200:
          this.setScopesErrors({ scope: payload.scope, errors: {} })
          this.setScopeFlowErrors({ scope: payload.scope, errors: {} })
          break
        case 400:
          this.setErrors400(payload)
          break
        case 401:
          this.setErrors401(payload)
          break
        case 403:
          this.setErrors403(payload)
          break
        case 404:
          this.setErrors404(payload)
          break
        case 413:
          this.setErrors413(payload)
          break
        case 500:
          this.setErrors500(payload)
          break
        default:
          this.setErrorsNotFound(payload)
          break
      }
      return
    }

    this.setErrorsNotFound(payload)
  }
}
