<template>
  <div
    class="tw-absolute tw-z-50 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-[#00000069]"
  >
    <BaseLoader />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import BaseLoader from '@/components/common/BaseLoader.vue'

@Component({ components: { BaseLoader } })
export default class Loader extends Vue {}
</script>
