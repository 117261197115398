import { Store, Pinia } from 'pinia-class-component'
import type { IModalStore } from '@/types/ModalTypes'

@Store
export default class ParameterEditModalService extends Pinia {
  public store: IModalStore = {
    isOpen: false,
    isLoading: false,
    isError: false,
  }

  public get isOpen(): boolean {
    return this.store.isOpen
  }

  public get isLoading(): boolean {
    return this.store.isLoading
  }

  public get isError(): boolean {
    return this.store.isError
  }

  public open(): void {
    this.store.isOpen = true
  }

  public close(): void {
    this.store.isOpen = false
  }
}
