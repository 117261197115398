export default {
  common: {
    email: 'Email',
    password: 'Hasło',
    repeatPassword: 'Powtórz Hasło',
    dontRememberPassword: 'Nie pamiętasz hasła?',
    resetPassword: 'Zresetuj hasło',
    actualPassword: 'Aktualne hasło',
    newPassword: 'Nowe hasło',
    repeatNewPassword: 'Powtórz nowe hasło',
    login: 'Zaloguj',
    confirm2FA: 'Potwierdź kodem z aplikacji',
    loginWithGoogle: 'Logowanie kontem Google',
    dontHaveAccount: 'Nie masz jeszcze konta?',
    doYouHaveAccount: 'Masz już konto?',
    createAccount: 'Załóż konto',
    or: 'Lub',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    name: 'Nazwa',
    yes: 'Tak',
    no: 'Nie',
    value: 'Wartość',
    phone: 'Telefon',
    headerTime: 'Czas Twojej sesji upłynie za',
    agree: 'Zgadzam się na',
    termsOfUse: 'warunki korzystania z usług',
    enable2fa: 'Włącz dwuetapową weryfikację (TOTP Authenticator)',
    passwordRequiredRules: 'Wymagane',
    chooseCompany: 'Wybierz firmę',
    chooseDept: 'Wybierz dział',
    chooseUnit: 'Wybierz jednostkę',
    identifier: 'Identyfikator',
    filterByStatus: 'Filtruj po statusie',
    createdAt: 'Data utworzenia',
    endDate: 'Data końcowa',
    position: 'pozycji',
    show: 'Pokaż',
    positionFrom: 'Pozycje od',
    total: 'łącznie',
    to: 'do',
    of: 'z',
    type: 'Typ',
    other: 'Inne',
    all: 'Wszystkie',
    role: 'Rola',
    description: 'Opis',
    notFound: 'Nie znaleziono strony',
    attribute: 'Atrybut',
    additionalInfo: 'Dodatkowe informacje',
    company: 'Firma',
    unit: 'Jednostka organizacyjna',
    search: 'Szukaj',
    allow: 'Pozwól',
    deny: 'Zabroń',
    prefix: 'Prefiks',
    hidden: 'Ukryty',
    passwordRules: {
      minLength: 'Minimum 8 znaków',
      specialChar: 'Zawiera min 1 znak specjalny',
      number: 'Zawiera min 1 cyfrę',
      upperCase: 'Zawiera min 1 wielką literę',
      lowerCase: 'Zawiera min 1 małą literę',
      baseInfo: 'Podst. zasady bezpieczeństwa dot. haseł',
    },
    dashboard: {
      menu: {
        risk: 'Zarządzanie ryzykiem',
        events: 'Zarządzanie zdarzeniami i incydentami',
        contextCompany: 'Kontekst jednostka',
        contextRole: 'Kontekst rola',
        selectUnit: 'Wybierz jednostkę',
        selectRole: 'Wybierz rolę',
      },
    },
    sessionTimeout: {
      title: 'Zbliża się koniec sesji',
      message: 'Twoja sesja wkrótce wygaśnie i zostaniesz wylogowany/a.',
      stillTimeLeftSession: 'Do końca sesji pozostało',
      ExtendSession: 'Przedłuż sesję aby kontynuować pracę.',
      buttons: {
        logout: 'Wyloguj się',
        extendSession: 'Przedłuż sesję',
      },
    },
    modals: {
      accept: 'Zgadzam się',
      acceptToProceed: 'Aby kontynuować, zatwierdź wszystkie zgody',
      unsavedChanges:
        'Masz niezapisane zmiany, czy napewno chcesz kontynuować?',
      confirmAction: 'Potwierdź akcję',
    },
    status: 'Status',
    none: 'Brak',
    active: 'Aktywny',
    actions: 'Akcje',
    number: 'Numer',
    topic: 'Temat',
    notifications: {
      saveSuccess: 'Zmiany zostały zapisane',
      noRoleSelected: 'Wybierz rolę przed kontynuacją',
      errorCantSave: 'Nie udało się zapisać zmian',
      passwordChanged: 'Hasło zostało zmienione pomyslnie',
      fillAllFields: 'Wypełnij wszystkie wymagane pola',
      phoneUpdated: 'Zmiany zostały zapisane',
    },
    redirect: 'Przekierowuję...',
    logout: 'Zostałeś wylogowany',
    logoutDescription: 'Zaraz zostaniesz przekierowany na stronę logowania',
    asset: 'Aktywo',
    chooseAsset: 'Wybierz aktywo',
    title: 'Tytuł',
    comment: 'Komentarz',
    confirm2FATitle: 'Weryfikacja dwuskładnikowa',
    confirm2FAPara:
      'Dla dodatkowego potwierdzenia twojego dostępu do konta, prosimy o podanie kodu z aplikacji autoryzacyjnej.',
    noRoles: 'Użytkownik nie posiada przypisanych ról w tej jednostce',
    captcha: 'Captcha',
    invalidCaptcha: 'Nieprawidłowa captcha',
    solveCaptcha: 'Rozwiąż zadanie aby kontynuować',
    sessionExpired: 'Sesja wygasła',
    failedToLoad: 'Nie udało się załadować captcha. Spróbuj ponownie.',
    day: 'dzień',
    days: 'dni',
    lackOfData: 'Brak danych',
    noAccess: 'Brak dostępu',
    fieldRequired: 'Pole wymagane',
    newError: 'Zapisz poprzednio dodany element aby dodać nowy',
    fillRequiredFields: 'Wypełnij pola',
    loginPage: 'Strona logowania',
    emailRequired: 'Email jest wymagany',
  },
}
