import { errorsService } from '@/main'
import client from '@/services/client'
import type { ILoginForm, IRegisterForm, IUserAuth } from '@/types/UserTypes'
import { isAxiosError } from 'axios'
import { errorsEnum } from '@/types/ErrorTypes'

interface IConfirmEmailResponse {
  tokenApi: string
}
interface IGen2FAResult {
  QR_Image: string
  secret: string
}

interface IManagePayload {
  option: 'accept_request' | 'email_verification' | 'authenticator_verification'
  code?: string
  user_id?: number
}
class AuthRepository {
  public async login(form: ILoginForm): Promise<IUserAuth> {
    return await client
      .post<IUserAuth>('auths/login', form)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.Login,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async logout(): Promise<void> {
    return await client
      .post<IUserAuth>('auths/logout')
      .then(() => {
        return
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.Login,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async reauth(): Promise<IUserAuth> {
    return await client
      .get<IUserAuth>('auths')
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.Reauth,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async add(form: IRegisterForm): Promise<IRegisterForm> {
    return await client
      .post(`auths/register`, form)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.AddRegistration,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async sendEmail(): Promise<void> {
    try {
      await client.get<boolean>('auths/2fa/email')
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.SendEmail,
          response: error.response,
        })
      }

      throw error
    }
  }

  public async confirmEmail(code: string): Promise<IConfirmEmailResponse> {
    try {
      const token = await client.post<IConfirmEmailResponse>(
        'auths/2fa/email',
        { code: code }
      )
      return token.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.SendConfirmEmail,
          response: error.response,
          ignoreToast: true,
        })
      }

      throw error
    }
  }

  // in case we want to confirm an action
  public async confirm2FA(
    token: string
  ): Promise<IConfirmEmailResponse | false> {
    try {
      const result = await client.post<IConfirmEmailResponse | false>(
        'auths/2fa/authenticator',
        { token: token }
      )
      return result.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.SendConfirmEmail,
          response: error.response,
          ignoreToast: true,
        })
      }
      throw error
    }
  }

  // in case we are assigning new 2fa
  public async confirmNew2FA(
    token: string,
    secret: string
  ): Promise<IConfirmEmailResponse | false> {
    try {
      const result = await client.post<IConfirmEmailResponse | false>(
        'auths/2fa/authenticator',
        { token: token, secret: secret }
      )
      return result.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.SendConfirmEmail,
          response: error.response,
        })
      }
      throw error
    }
  }

  public async manage2FA(payload: IManagePayload): Promise<boolean> {
    try {
      const result = await client.patch<boolean>(
        'auths/2fa/authenticator',
        payload
      )
      return result.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.Manage2FA,
          response: error.response,
        })
      }

      throw error
    }
  }

  public async generate2FACode(): Promise<IGen2FAResult> {
    try {
      const result = await client.get<IGen2FAResult>('auths/2fa/authenticator')
      return result.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        const message =
          'The reset expiration time has passed. You need to request a new 2FA reset.'
        if (error.response?.data?.checkGenerated[0] === message) {
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.Manage2FA,
            response: error.response,
            ignoreToast: true,
          })
        } else {
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.Manage2FA,
            response: error.response,
          })
        }
      }

      throw error
    }
  }

  public async resetAuth(): Promise<void> {
    try {
      return await client.get('auths/2fa/authenticator/reset')
    } catch (e) {
      if (isAxiosError(e)) {
        if (!e.response) throw e
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.Reset2FA,
          response: e.response,
        })
      }
    }
  }
}

export default new AuthRepository()
