<template>
  <div
    class="c-notificationsList tw-absolute -tw-left-[100px] tw-z-10 -tw-ml-2 tw-mt-2 tw-w-[340px] tw-rounded-xl tw-bg-white tw-pt-3 tw-shadow-xl"
  >
    <h3 class="tw-mb-4 tw-px-4 tw-text-lg tw-font-medium tw-tracking-wide">
      {{ $t('views.notifications.title') }}
    </h3>

    <div
      class="tw-mb-4 tw-border-b tw-border-gray-200 tw-px-4 tw-font-medium tw-text-gray-500 dark:tw-border-gray-700 dark:tw-text-gray-400"
    >
      <ul class="-tw-mb-px tw-flex tw-flex-wrap tw-pl-0">
        <li class="tw-me-2 tw-cursor-pointer">
          <p
            class="tw-mb-0 tw-inline-block tw-rounded-t-lg tw-border-b-2 tw-border-transparent tw-p-2 tw-text-sm tw-font-medium hover:tw-border-gray-300 hover:tw-border-b-black hover:tw-text-black hover:tw-duration-500 dark:hover:tw-text-gray-300"
            :class="{ 'tw-border-b-black tw-text-black': isActiveTab === 0 }"
            @click="isActiveTab = 0"
          >
            {{ $t('views.notifications.all') }}
          </p>
        </li>
        <li
          class="tw-me-2 tw-cursor-pointer"
          v-for="type in types"
          :key="type.id"
        >
          <p
            class="tw-mb-0 tw-inline-block tw-rounded-t-lg tw-border-b-2 tw-border-transparent tw-p-2 tw-text-sm tw-font-medium hover:tw-border-gray-300 hover:tw-border-b-black hover:tw-text-black hover:tw-duration-500 dark:hover:tw-text-gray-300"
            :class="{
              'tw-border-b-black tw-text-black': isActiveTab === type.id,
            }"
            @click="isActiveTab = type.id"
          >
            {{ type.name }}
          </p>
        </li>
      </ul>
    </div>
    <h3
      class="tw-pl-4 tw-text-xs tw-font-bold tw-uppercase tw-tracking-wide tw-text-blue19"
    >
      {{ $t('views.notifications.today') }}
    </h3>
    <ul class="tw-pl-0">
      <li
        class="tw-cursor-pointer hover:tw-bg-[#eff2f3] hover:tw-duration-500"
        :class="{
          'tw-flex tw-items-center tw-bg-[#eff2f3]':
            notification.read_at === null,
        }"
        v-for="notification in todayNotifications"
        :key="`notification-${notification.id}`"
        @click="goToAssigment(notification.assignment_id)"
      >
        <div class="tw-px-4 tw-py-2">
          <h4 class="tw-text-base">{{ notification.message }}</h4>
          <p class="tw-mb-0 tw-text-sm">
            {{ notification.assigment.title }}
          </p>
        </div>
        <span
          v-if="notification.read_at === null"
          class="p-1 tw-mr-2 tw-block tw-rounded-full tw-bg-brand"
        ></span>
      </li>
    </ul>
    <h3
      class="tw-pl-4 tw-text-xs tw-font-bold tw-uppercase tw-tracking-wide tw-text-blue19"
    >
      {{ $t('views.notifications.other') }}
    </h3>
    <ul class="tw-pl-0">
      <li
        class="tw-cursor-pointer hover:tw-bg-[#eff2f3] hover:tw-duration-500"
        :class="{
          'tw-flex tw-items-center tw-bg-[#eff2f3]':
            notification.read_at === null,
        }"
        v-for="notification in otherNotifications"
        :key="`notificationOther-${notification.id}`"
        @click="goToAssigment(notification.assignment_id)"
      >
        <div class="tw-px-4 tw-py-2">
          <h4 class="tw-text-base">{{ notification.message }}</h4>
          <p class="tw-mb-0 tw-text-sm">
            {{ notification.assigment.title ?? '' }}
          </p>
        </div>
        <span
          v-if="notification.read_at === null"
          class="p-1 tw-mr-2 tw-block tw-rounded-full tw-bg-brand"
        ></span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'
import type { INotification, INotificationType } from '@/types/TasksTypes'
import taskRepository from '@/repositories/TaskRepository'
import { routeNames } from '@/router/types'

@Component
export default class NotificationsList extends Vue {
  @Prop({ required: true }) public notifications!: INotification[]

  public isActiveTab: number = 0
  public types: INotificationType[] = []
  public routeNames: typeof routeNames = routeNames

  public async loadTypes(): Promise<void> {
    await taskRepository.getNotificationsTypes().then((response) => {
      this.types = response.data
    })
  }

  public get todayNotifications(): INotification[] {
    if (this.isActiveTab === 0) {
      return this.notifications.filter((notification) =>
        this.isToday(new Date(notification.created_at))
      )
    }
    return this.notifications.filter(
      (notification) =>
        this.isToday(new Date(notification.created_at)) &&
        this.isActiveTab === notification.type_id
    )
  }

  public get otherNotifications(): INotification[] {
    if (this.isActiveTab === 0) {
      return this.notifications.filter(
        (notification) => !this.isToday(new Date(notification.created_at))
      )
    } else {
      return this.notifications.filter(
        (notification) =>
          !this.isToday(new Date(notification.created_at)) &&
          this.isActiveTab === notification.type_id
      )
    }
  }

  private isToday(date: Date): boolean {
    const today = new Date()
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    )
  }

  public async mounted(): Promise<void> {
    await this.loadTypes()
  }

  public goToAssigment(id: number): void {
    this.$router.push({ name: this.routeNames.Tasks, params: { id } })
  }
}
</script>
