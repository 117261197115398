<template>
  <div class="c-reportsTab tw-mt-8">
    <UITable
      :headers="tableHeaders"
      :sortFill="sortFill"
      :sortDirection="sortDirection"
      @sort="setSortFill"
      :numberOfAllResults="reports.length"
      class="tw-my-4"
      :with-pagination="withPagination"
      :with-number-of-results="withNumberOfResults"
    >
      <tr
        v-for="report in reports"
        :key="`reportRow_${report.id}`"
        class="tw-align-top tw-leading-6"
      >
        <td class="tw-p-2.5 tw-leading-6">{{ report.id }}</td>
        <td class="tw-p-2.5 tw-leading-6">{{ report.submitter }}</td>
        <td class="tw-p-2.5 tw-leading-6">{{ report.email }}</td>
        <td class="tw-p-2.5 tw-leading-6">
          <UIStatus :status="report.status" />
        </td>
        <td class="tw-p-2.5 tw-leading-6">
          {{ report.asset }}
        </td>
        <td class="tw-p-2.5 tw-leading-6">
          {{ report.system_criticality }}
        </td>
        <td class="tw-p-2.5 tw-leading-6">
          {{ report.created_at }}
        </td>
        <td class="tw-p-2.5 tw-leading-6">
          {{ report.notification }}
        </td>
      </tr>
      <template #topRightSide>
        <div class="tw-relative tw-flex tw-flex-wrap-reverse tw-items-center">
          <UIInput
            v-model="searchingForm.search"
            id="search"
            name="search"
            ui-class="tw-flex tw-relative !tw-mt-0"
            input-group-class="with-icon !tw-w-[230px] !tw-mb-5 !tw-mt-0 tw-mr-2"
          >
            <font-awesome-icon
              icon="icon fa-solid fa-magnifying-glass"
              class="tw-absolute !tw-top-[14px] tw-right-5"
            />
          </UIInput>
        </div>
      </template>
    </UITable>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import UIStatus from '@/components/UI/UIStatus.vue'
import UIInput from '@/components/UI/UIInput.vue'
import UIButton from '@/components/UI/UIButton.vue'
import UITable from '@/components/UI/UITable.vue'
import { ITableHeader } from '@/types/TableTypes'

interface ISearchingForm {
  search: string
}

interface IReports {
  id: string
  submitter: string
  email: string
  status: string
  asset: string
  system_criticality: string
  created_at: string
  notification: string
}

@Component({
  components: { UITable, UIButton, UIInput, UIStatus },
})
export default class ReportsTab extends Vue {
  public sortFill: string = 'id'
  public sortDirection: string = 'asc'
  public withPagination: boolean = true
  public withNumberOfResults: boolean = true
  public searchingForm: ISearchingForm = {
    search: '',
  }

  public setSortFill(fill: string): void {
    if (this.sortFill === fill) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      return
    }
    this.sortFill = fill
  }

  public get tableHeaders(): ITableHeader[] {
    return [
      {
        label: this.$t('views.assets.id'),
        fill: 'id',
        sort: true,
      },
      {
        label: this.$t('views.assets.submitter'),
        fill: 'submitter',
        sort: true,
      },
      {
        label: this.$t('views.assets.email'),
        fill: 'email',
        sort: true,
      },
      {
        label: this.$t('views.assets.status'),
        fill: 'status',
        sort: true,
      },
      {
        label: this.$t('views.assets.asset'),
        fill: 'asset',
        sort: true,
      },
      {
        label: this.$t('views.assets.systemCriticality'),
        fill: 'systemCriticality',
        sort: true,
      },
      {
        label: this.$t('views.assets.createdAt'),
        fill: 'created_at',
        sort: true,
      },
      {
        label: this.$t('views.assets.notifications'),
        fill: 'notifications',
        sort: true,
      },
    ]
  }

  public reports: IReports[] = [
    {
      id: '-',
      submitter: 'Berger Piotr',
      email: 'piotr.berger.pb@gmail.com',
      status: 'PROCESOWANY',
      asset: '432432',
      system_criticality: 'Istotny',
      created_at: '2023-10-25',
      notification: '-',
    },
    {
      id: '-',
      submitter: 'Sz Ola',
      email: 'as070584@gmail.com',
      status: 'ZAKOŃCZONY',
      asset: '432432',
      system_criticality: 'Istotny',
      created_at: '2023-11-05',
      notification: '-',
    },
  ]
}
</script>
