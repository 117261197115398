export default {
  attributes: {
    attributes: 'Atrybuty',
    newAttributes: 'Nowy atrybut',
    editAttributes: 'Edycja atrybutu',
    activeAttribute: 'Czy atrybut aktywny?',
    name: 'Nazwa',
    yes: 'Tak',
    no: 'Nie',
    active: 'Aktywny',
    inactive: 'Nieaktywny',
  },
  notifications: {
    added: 'Atrybut został dodany',
    changed: 'Atrybut został zmieniony',
  },
}
