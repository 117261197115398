export default {
  glossaries: {
    glossaries: 'Słowniki',
    glossariesImport: 'Import słowników',
    overwriting: 'Nadpisywanie',
    glossaryType: 'Typ słownika',
    identification: 'Identyfikacja',
    system: 'System',
    assetCategory: 'Kategoria aktywów',
    risky: 'Ryzykowne',
    tasks: 'Zadania',
    formType: 'Typ formularza',
    startOfForm: 'Początek formularza',
    addForm: 'Dodaj formularz',
    name: 'Nazwa',
    type: 'Typ',
    active: 'Aktywny',
    editGlossaryForm: 'Edycja formularza',
    status: 'Status',
    header: 'Nagłówek',
    positions: 'Pozycje',
    formKind: 'Następny nagłówek',
    endOfForm: 'Koniec formularza',
    separator: 'Separator',
    weight: 'Waga',
    addFormKind: 'Dodaj rodzaj formularza',
  },
}
