<template>
  <div
    class="c-numberOfResults tw-mb-3 tw-mr-3 tw-flex tw-items-baseline tw-text-sm"
  >
    <p class="tw-mb-0 tw-mr-2">{{ $t('common.show') }}</p>
    <UISelect
      :options="numberOfResultsOptions"
      id="numberOfResults"
      inputGroupClass="!tw-w-16"
      v-model="numberOfResults"
      @change="setResults"
    >
    </UISelect>
    <p class="tw-mb-0 tw-ml-2">{{ $t('common.position') }}</p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { SelectOption } from '@/types/CommonTypes'
import UISelect from '@/components/UI/UISelect.vue'
import type { Nullable } from '@/types/Nullable'

@Component({
  components: { UISelect },
  emits: ['set-results', 'update:modelValue'],
})
export default class NumberOfResults extends Vue {
  @Prop({ required: true })
  public showedResults!: number

  @Prop({ required: false })
  public modelValue!: number

  public numberOfResults: Nullable<string | number> = null

  public get numberOfResultsOptions(): SelectOption[] {
    return [
      {
        value: 10,
        label: 10,
      },
      {
        value: 25,
        label: 25,
      },
      {
        value: 50,
        label: 50,
      },
      {
        value: 100,
        label: 100,
      },
    ]
  }

  public setResults(): void {
    this.$emit('set-results', this.numberOfResults)
    this.$emit('update:modelValue', this.numberOfResults)
  }

  public mounted(): void {
    this.numberOfResults = this.numberOfResultsOptions[1].value

    if (this.modelValue) {
      this.numberOfResults = this.modelValue
      return
    }
    this.numberOfResults = this.numberOfResultsOptions[0].value
  }
}
</script>
