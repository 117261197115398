<template>
  <div class="c-uiSelect tw-text-start" :class="uiClass">
    <div
      v-if="!labelOnTop"
      class="input-group input-group-outline tw-mb-0"
      :class="[
        { 'is-invalid': isError && modelValue && modelValue.length === 0 },
        {
          'focus is-focused tw-select-none': isFocusedClass || labelOnBorder,
        },
        {
          withFocus: isFocused && modelValue === null && !labelOnBorder,
        },
        inputGroupClass,
      ]"
    >
      <label class="form-label" v-if="label">
        {{ label }}
        <span
          v-if="isMandatory"
          class="text-danger tw-mx-1 -tw-mt-1 tw-text-[14px]"
          >*</span
        >
      </label>
      <select
        class="form-control cursor-pointer !tw-pr-40"
        v-bind="$attrs"
        :value="modelValue"
        @change="changeModelValue($event)"
        @focus="handleFocus"
        @blur="handleBlur"
      >
        <option value="" disabled selected hidden v-if="placeholder !== ''">
          {{ placeholder }}
        </option>
        <template v-if="options.length > 0">
          <option
            class="tw-bg-white tw-text-black dark:tw-bg-neutral-900 dark:tw-text-greyc9"
            v-for="(option, index) in options"
            :value="option.value"
            :key="`select_${index}_${option.label}`"
            :disabled="option.disabled === true"
          >
            {{ option.label }}
          </option>
        </template>
      </select>
      <font-awesome-icon
        icon="icon fa-solid fa-chevron-down"
        :class="{ icon: true }"
        class="tw-pointer-events-none tw-mt-1 tw-text-xs"
      />
    </div>
    <div
      v-else-if="labelOnTop"
      class="input-group input-group-outline tw-mb-0"
      :class="[
        { 'is-invalid': isError },
        { 'focus is-focused': isFocusedClass },
        { 'tw-initial tw-flex-col': labelOnTop },
        inputGroupClass,
      ]"
    >
      <label class="tw-accent-red-700 dark:!tw-text-greyc9" v-if="label">
        {{ label }}
      </label>
      <div :class="[{ 'tw-relative': labelOnTop }]">
        <select
          class="form-control cursor-pointer tw-z-10"
          v-bind="$attrs"
          :value="modelValue"
          @change="changeModelValue($event)"
        >
          <option
            value=""
            disabled
            :selected="!modelValue"
            hidden
            v-if="placeholder !== ''"
          >
            {{ placeholder }}
          </option>
          <template v-if="options.length > 0">
            <option
              class="tw-bg-white tw-text-black dark:tw-bg-neutral-900 dark:tw-text-greyc9"
              v-for="(option, index) in options"
              :value="option.value"
              :key="`select_${index}_${option.label}`"
              :selected="modelValue === option.value"
              :disabled="option.disabled === true"
            >
              {{ option.label }}
            </option>
          </template>
        </select>
        <font-awesome-icon
          icon="icon fa-solid fa-chevron-down"
          :class="{ icon: true }"
          class="tw-pointer-events-none tw-mt-1 tw-text-xs"
        />
      </div>
    </div>
    <slot />
    <span
      class="badge badge-danger d-block tw-mt-1 !tw-bg-red-600"
      :class="errorClass"
      role="alert"
      v-if="isError && error.length"
    >
      <strong class="tw-text-wrap tw-leading-5">{{ error }}</strong>
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { TranslateResult } from 'vue-i18n'
import type { SelectOption } from '@/types/CommonTypes'

@Component({
  emits: ['change', 'update:modelValue', 'selectOption'],
})
export default class UISelect extends Vue {
  @Prop()
  public modelValue!: string | number | null

  @Prop({ default: [] })
  public options!: SelectOption[]

  @Prop({ required: false })
  public label!: string | TranslateResult

  @Prop({ required: false })
  public isError!: boolean

  @Prop({ required: false })
  public error!: string

  @Prop({ required: false })
  public uiClass!: string

  @Prop({ required: false })
  public inputGroupClass!: string

  @Prop({ required: false })
  public errorClass!: string

  @Prop({ required: false, default: false })
  public labelOnTop!: boolean

  @Prop({ required: false, default: false })
  public labelOnBorder!: boolean

  @Prop({ required: false, default: false })
  public isMandatory!: boolean

  @Prop({ required: false, default: '' })
  public placeholder!: string

  public isFocused: boolean = false

  public handleFocus(): void {
    this.isFocused = true
  }

  public handleBlur(): void {
    this.isFocused = false
  }

  public changeModelValue(event: Event): void {
    const value = (event.target as HTMLSelectElement).value
    const valNum = Number(value)
    if (!isNaN(valNum) && value !== '') {
      this.$emit('update:modelValue', Number(value))
    } else {
      this.$emit('update:modelValue', value)
    }
    this.$emit('change', (event.target as HTMLInputElement).value)
  }

  public get isFocusedClass(): boolean {
    if (typeof this.modelValue === 'string') {
      return Boolean(this.modelValue.length)
    }

    return this.modelValue !== null
  }
}
</script>

<style lang="scss" scoped>
.form-control {
  border-radius: 0.375rem !important;
  height: 44px !important;
}

label,
.form-label {
  color: var(--header-color) !important;
}

.input-group.input-group-outline.is-focused .form-label {
  color: var(--header-color) !important;

  &::after {
    border-top-color: #d2d6da;
    box-shadow: inset 0 0 #d2d6da;
  }

  &::before {
    border-top-color: #d2d6da;
    box-shadow: inset 0 0 #d2d6da;
  }
}

.input-group.input-group-outline.is-focused .form-label + .form-control {
  border: 0;
  border-color: #d2d6da !important;
  box-shadow: inset 1px 0 #d2d6da, inset -1px 0 #d2d6da, inset 0 -1px #d2d6da;
}

.icon {
  position: absolute;
  right: 10px;
  top: 10px;
  transform: rotate(0deg);
}

select:focus + .icon {
  transform: rotate(180deg);
  transition: transform 0.3s linear;
}

select.form-control:focus {
  border: 1px solid #d71b49;
}

.input-group.input-group-outline.is-focused.withFocus .form-label {
  color: #d71b49;
}
.input-group.input-group-outline.is-focused.withFocus .form-label:before,
.input-group.input-group-outline.is-focused.withFocus .form-label:after {
  border-width: 1px;
  opacity: 1;
  box-shadow: inset 0 1px #d71b49;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out !important;
}
.input-group.input-group-outline.is-focused.withFocus
  .form-label
  + .form-control {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  border: 1px solid #d81b60 !important;
  border-top-color: transparent !important;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out !important;
}

.c-uiSelect .input-group.input-group-outline.is-invalid .form-control {
  background-image: none;
}

.input-group.input-group-outline .form-control {
  padding-right: 28px !important;
}
</style>
