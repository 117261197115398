import { Pinia, Store } from 'pinia-class-component'
import type { Nullable } from '@/types/Nullable'
import type {
  IAsset,
  IAssetAdditionalFormData,
  IAssetCriticality,
  IAssetCriticalityForm,
  IAssetFormData,
  IAssetRisk,
} from '@/types/AssetsTypes'
import AssetsRepository from '@/repositories/AssetsRepository'
import { errorsService } from '@/main'
import { errorsEnum } from '@/types/ErrorTypes'

interface IAssetsStore {
  assets: IAsset[]
  asset: Nullable<IAsset>
  criticality: Nullable<IAssetCriticality>
  isLoading: boolean
  isError: boolean
  risks: IAssetRisk[]
  risk: Nullable<IAssetRisk>
}

@Store
export default class AssetsService extends Pinia {
  public store: IAssetsStore = {
    assets: [],
    asset: null,
    criticality: null,
    isLoading: false,
    isError: false,
    risks: [],
    risk: null,
  }
  public get assets(): IAsset[] {
    return this.store.assets
  }

  public get asset(): Nullable<IAsset> {
    return this.store.asset
  }

  public get isLoading(): boolean {
    return this.store.isLoading
  }

  public get isError(): boolean {
    return this.store.isError
  }

  public get risks(): IAssetRisk[] {
    return this.store.risks
  }

  public get risk(): Nullable<IAssetRisk> {
    return this.store.risk
  }

  public get criticality(): Nullable<IAssetCriticality> {
    return this.store.criticality
  }

  public clearAssetAndCriticality(): void {
    this.store.asset = null
    this.store.criticality = null
    errorsService.clearScopeErrors(errorsEnum.SaveAssetCriticality)
    errorsService.clearScopeErrors(errorsEnum.SaveAsset)
  }

  public async loadAssets(): Promise<void> {
    this.store.isLoading = true
    await AssetsRepository.getAssets()
      .then((response) => {
        this.store.assets = response
      })
      .catch(() => {
        this.store.assets = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadAssetRisks(id: number): Promise<IAssetRisk[]> {
    this.store.isLoading = true
    return await AssetsRepository.getAssetRisks(id)
      .then((response) => {
        this.store.risks = response
        return response
      })
      .catch(() => {
        this.store.risks = []
        this.store.isError = true
        return []
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadAllAssetRisks(): Promise<IAssetRisk[]> {
    this.store.isLoading = true
    return await AssetsRepository.getAllAssetRisks()
      .then((response) => {
        this.store.risks = response
        return response
      })
      .catch(() => {
        this.store.risks = []
        this.store.isError = true
        return []
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadRisk(id: number): Promise<Nullable<IAssetRisk>> {
    this.store.isLoading = true
    return await AssetsRepository.getAssetRisk(id)
      .then((response) => {
        this.store.risk = response
        return response ?? null
      })
      .catch(() => {
        this.store.risk = null
        this.store.isError = true
        return null
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadAsset(id: number): Promise<void> {
    this.store.isLoading = true
    this.store.asset = null
    await AssetsRepository.getAsset(id)
      .then((response) => {
        this.store.asset = response
      })
      .catch(() => {
        this.store.asset = null
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadLastAsset(userId: number): Promise<void> {
    this.store.isLoading = true
    this.store.asset = null
    await AssetsRepository.getLastAsset(userId)
      .then((response) => {
        this.store.asset = response
      })
      .catch(() => {
        this.store.asset = null
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addNewAsset(userId: number): Promise<number> {
    this.store.isLoading = true
    this.store.asset = null
    return await AssetsRepository.addNewAsset(userId)
      .then((response) => {
        return response ?? 0
      })
      .catch(() => {
        this.store.asset = null
        this.store.isError = true
        return 0
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadCriticality(id: number): Promise<void> {
    this.store.isLoading = true
    this.store.criticality = null
    await AssetsRepository.getAssetCriticality(id)
      .then((response) => {
        this.store.criticality = response
      })
      .catch(() => {
        this.store.criticality = null
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async updateAssetField(
    id: number,
    formAsset: IAssetFormData,
    formAdditionalData: IAssetAdditionalFormData
  ): Promise<void> {
    this.store.isLoading = true
    await AssetsRepository.updateField(id, formAsset, formAdditionalData)
      .then((response) => {
        this.store.asset = response
      })
      .catch((error) => {
        this.store.isError = true
        throw error
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async updateCriticalityField(
    id: number,
    form: IAssetCriticalityForm
  ): Promise<void> {
    this.store.isLoading = true
    await AssetsRepository.updateFieldCriticality(id, form)
      .then((response) => {
        this.store.criticality = response
      })
      .catch(() => {
        this.store.criticality = null
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async saveAsset(
    id: number,
    formAsset: IAssetFormData,
    formAdditionalData: IAssetAdditionalFormData
  ): Promise<void> {
    this.store.isLoading = true
    await AssetsRepository.saveAsset(id, formAsset, formAdditionalData)
      .then(() => {})
      .catch((errors) => {
        this.store.isError = true
        throw errors
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async saveAssetCriticality(
    id: number,
    form: IAssetCriticalityForm
  ): Promise<void> {
    this.store.isLoading = true
    await AssetsRepository.saveCriticality(id, form)
      .then((response) => {
        this.store.criticality = response
      })
      .catch(() => {
        this.store.criticality = null
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }
}
